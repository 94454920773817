<template>
  <v-app-bar
    absolute
    color="transparent"
    flat
    height="85"
    class="mt-7"
  >
    <v-container class="px-0 text-right d-flex">
      <v-toolbar-title
        class="font-weight-light hidden-xs-only"
        v-text="title"
      />
      <v-spacer />

      <v-menu
        bottom
        right
        min-width="200"
        offset-y
        origin="top right"
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            to="/"
            v-bind="attrs"
            min-height="48"
            min-width="40"
            text
            v-on="on"
          >
            <v-icon
              :left="$vuetify.breakpoint.mdAndUp"
              size="20"
            >
              mdi-web
            </v-icon>

            <span
              class="hidden-sm-and-down"
              v-text="$store.state.appLanguage"
            />
          </v-btn>
        </template>

        <v-list
          :tile="false"
          dense
        >
          <app-bar-item>
            <v-list-item-content
              data-cy="btnChangerLangueFr"
              @click="onChangerLangue('fr')"
            >
              <v-list-item
                link
              >
                <v-list-item-title>
                  <span class="pl-4">Français</span>
                </v-list-item-title>
              </v-list-item>
            </v-list-item-content>
          </app-bar-item>
          <app-bar-item>
            <v-list-item
              link
            >
              <v-list-item-content
                data-cy="btnChangerLangueFr"
                @click="onChangerLangue('en')"
              >
                <v-list-item-title>
                  <span class="pl-4">English</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </app-bar-item>
        </v-list>
      </v-menu>
    </v-container>
  </v-app-bar>
</template>

<script>
  export default {
    name: 'PagesCoreAppBar',

    data: () => ({
      titles: {
        '/pages/login': 'Login Page',
        '/pages/pricing': 'Pricing Page',
        '/pages/register': 'Register Page',
      },
    }),

    computed: {
      title () {
        return this.titles[this.$route.path]
      },
    },
    methods: {
      onChangerLangue(langue) {
        this.$store.commit('SET_APP_LANGUAGE', langue)
      },
    },
  }
</script>
